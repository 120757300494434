// extracted by mini-css-extract-plugin
export var baseAltWrapper = "Sections-module--baseAltWrapper--e89b5";
export var header = "Sections-module--header--b238f";
export var list = "Sections-module--list--c36ad";
export var neutralWrapper = "Sections-module--neutralWrapper--5a1ff";
export var paragraph = "Sections-module--paragraph--a55ed";
export var picture = "Sections-module--picture--281f7";
export var section = "Sections-module--section--5309a";
export var sectionBaseAltWrapper = "Sections-module--sectionBaseAltWrapper--76027 Sections-module--section--5309a";
export var sectionNeutralWrapper = "Sections-module--sectionNeutralWrapper--08b5b Sections-module--section--5309a";
export var svg = "Sections-module--svg--59544";