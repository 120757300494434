import * as React from "react"
import * as styles from "./Sections.module.scss";
import {StaticImage} from "gatsby-plugin-image";

export const Sections = () => {
  return (
    <div>
      <svg className={styles.svg} aria-hidden="true" viewBox="0 0 1280 90" preserveAspectRatio="none">
        <path
          d="M 0,90 V 23.75 l 23.4319,5.3125 c 23.4328,5.3125 70.2978,15.9376 117.1631,12.3126 46.866,-3.6875 93.73,-21.5627 140.594,-13.3752 46.866,8.25 93.731,42.6251 140.596,50.7501 46.865,8.125 93.73,-10 140.594,-10.3125 46.864,-0.3125 93.73,17.1875 140.596,20 24.917,1.4956 49.833,-1.1612 74.749,-3.8174 21.949,-2.34 43.897,-4.68 65.847,-4.1819 46.864,1 93.729,14.9999 140.594,3.6249 46.865,-11.375 93.725,-48.0001 140.595,-59.5001 46.87,-11.4375 93.73,2.3126 140.59,-17.37489 C 1270.46,5.02124 1275.35,2.62 1280,0 v 90 z"
          fill="var(--lmdj-background-baseAlt)"
        />
      </svg>

      <div className={styles.baseAltWrapper}>

        <section id="aires" className={styles.sectionBaseAltWrapper}>
          <StaticImage src="../../images/photos/creation-bretagne-aires-de-jeux.jpeg" alt="aires de jeux"
                       className={styles.picture}/>
          <h3 className={styles.header}>
            Création d'aires de jeux
          </h3>
          <p className={styles.paragraph}>
            La Maison des Jeux vous propose de s’occuper de la création de vos espaces ludiques et sportifs avec une
            solution clé en main.
          </p>
          <p className={styles.paragraph}>
            Tout d’abord lors de notre première rencontre nous définissons ensemble vos besoins. A savoir, les tranches
            âges visées, le type de matériaux à utiliser, la surface amortissante que vous désirez, etc.
          </p>
          <p className={styles.paragraph}>
            Si vous le souhaitez, La Maison des Jeux prend en charge les demandes de devis auprès de plusieurs
            fournisseurs. Cette méthode présente l’avantage de s’adresser à un seul interlocuteur et d’avoir des
            propositions tarifaires de différentes marques.
          </p>
          <p className={styles.paragraph}>
            Une fois le devis final validé, nous commandons les différents jeux et dans un délai de 6 à 8 semaines nous
            débutons le chantier.
          </p>
          <p className={styles.paragraph}>
            La Maison des Jeux réalise ensuite les sols amortissants. Ces derniers peuvent être de différentes natures :
            gravier roulés, copeaux de bois et sols souples. Nous vous proposons une gamme de sols souples avec une
            palette de couleurs très large. Ces sols souples sont coulés sur place et mis en oeuvre dans les règles de
            l'art NF EN 1176, NF EN 1177 et suivant les préconisations du fabriquant.
          </p>
        </section>

        <section id="sols" className={styles.sectionBaseAltWrapper}>
          <StaticImage src="../../images/photos/sol-amortissant-souple.jpeg" alt="sol amortissant ou souple"
                       className={styles.picture}/>
          <h3 className={styles.header}>
            Sols amortissants ou souples
          </h3>
          <p className={styles.paragraph}>
            La Maison des Jeux réalise pour vous des sols amortissants coulés sur place aux normes NF EN 1176, NF EN
            1177,
            ce sol se compose de deux parties :
          </p>
          <ul className={styles.list}>
            <li>La sous couche amortissante qui peut faire plusieurs centimètres d’épaisseur suivant les hauteurs de
              chutes.
            </li>
            <li>La couche supérieure : Le toping, celui-ci se compose d’EPDM teinté dans la masse.</li>
          </ul>
          <p className={styles.paragraph}>
            Le sol amortissant se met en oeuvre généralement sur une dalle béton ou une surface enrobée. Plus rarement,
            il
            est possible de le réaliser sur de la grâve.
          </p>
          <p className={styles.paragraph}>
            Lors de nos chantiers de maintenance, il est possible de faire des réparations de sols amortissants.
          </p>
        </section>

      </div>


      <svg className={styles.svg} viewBox="0 0 1280 90" preserveAspectRatio="none">
        <path
          d="m 1280,0 v 66.25 l -23.4319,-5.3125 C 1233.1353,55.625 1186.2703,44.9999 1139.405,48.6249 1092.539,52.3124 1045.675,70.1876 998.811,62.0001 951.945,53.7501 905.08,19.375 858.215,11.25 c -46.865,-8.125 -93.73,10 -140.594,10.3125 -46.864,0.3125 -93.73,-17.1875 -140.596,-20 -24.917,-1.4956 -49.833,1.1612 -74.749,3.8174 -21.949,2.34 -43.897,4.68 -65.847,4.1819 C 389.565,8.5618 342.7,-5.4381 295.835,5.9369 248.97,17.3119 202.11,53.937 155.24,65.437 108.37,76.8745 61.51,63.1244 14.65,82.81189 9.54,84.97876 4.65,87.38 0,90 V 0 Z"
          fill="var(--lmdj-background-baseAlt)"
        />
      </svg>


      <div className={styles.neutralWrapper}>

        <section id="maintenance" className={styles.sectionNeutralWrapper}>
          <StaticImage src="../../images/photos/maintenance-bretagne-aires-de-jeux.jpeg"
                       alt="maintenance bretagne aires de jeux"
                       className={styles.picture}/>
          <h3 className={styles.header}>
            La maintenance, ce n'est pas un jeu !
          </h3>
          <p className={styles.paragraph}>
            Chaque passage périodique se décompose ainsi :
          </p>
          <ul className={styles.list}>
            <li>Création du registre de suivi de l'aire de jeux (notice du fabriquant, plan d'implantation, plan
              d'entretien etc.).
            </li>
            <li>Evaluation de l'aire de jeux, vérification de la conformité des structures de jeux suivant les normes en
              vigueur (gabarits de contrôles normés).
            </li>
            <li>Edition de la fiche de suivi pour chaque aire de jeux.</li>
            <li>Demande de devis pour remplacement des pièces endommagées auprès du fabriquant.</li>
            <li>Remplacement des pièces défectueuses par des pièces détachées neuves fournies par le fabriquant.</li>
            <li>Edition du récépissé de pose des pièces détachées.</li>
          </ul>
          <p className={styles.paragraph}>
            Le décret n°96-1136 du 18 décembre 1996 impose à l'exploitant de faire un suivi et de tenir un plan
            d'entretien de ses aires de jeux.
          </p>
        </section>

        <section id="contrats" className={styles.sectionNeutralWrapper}>
          <StaticImage src="../../images/photos/maintenance-bretagne-aires-de-jeux-2.jpeg"
                       alt="maintenance bretagne aires de jeux"
                       className={styles.picture}/>
          <h3 className={styles.header}>
            Nos contrats
          </h3>
          <p className={styles.paragraph}>
            Suivant vos besoins, plusieurs contrats périodiques vous sont proposés :
          </p>
          <ul className={styles.list}>
            <li>Le passage annuel<br/>(minimum légal).</li>
            <li>Le passage semestriel<br/>(recommandé pour les campings et hôtellerie de plein air).</li>
            <li>Le passage trimestriel<br/>(recommandé pour les collectivités).</li>
            <li>Le passage mensuel</li>
            <li>Chaque contrat périodique inclus un nettoyage annuel de vos aires de jeux à l'aide d'un nettoyeur haute
              pression.
            </li>
            <li>Chaque passage périodique inclus - si nécessaire - le remplacement de la petite quincaillerie
              défectueuse et un nettoyage (soufflage de l'aire de jeux, ramassage des papiers).
            </li>
          </ul>
        </section>

      </div>

    </div>
  )
}