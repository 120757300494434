import * as React from "react"
import {Header} from "../components/header/Header";
import {Anchors} from "../components/anchors/Anchors";
import {Sections} from "../components/sections/Sections";
import {Footer} from "../components/footer/Footer";
import {SEO} from "../components/seo/SEO";

// markup
const IndexPage = () => {
  return (
    <>
      <Header/>
      <Anchors/>
      <Sections/>
      <Footer/>
    </>
  )
}

export default IndexPage

export const Head = () => (
  <SEO />
)
