import * as React from "react"
import * as styles from './Header.module.scss';

export const Header = () => {
  return (
    <>
      <header className={styles.header}>
        <h1 className={styles.h1}>La Maison des Jeux</h1>
        <p className={styles.p}>Spécialiste de la pose d'aires de jeux en bois de robinier, en Bretagne.</p>
      </header>
      <svg className={styles.svg} aria-hidden="true" viewBox="0 0 1440 144" preserveAspectRatio="none">
        <path fill="var(--lmdj-background-neutral)"
              d="M-5558-4781v-106l26.361,8.5c26.362,8.5,79.085,25.5,131.809,19.7,52.724-5.9,105.446-34.5,158.168-21.4,52.724,13.2,105.447,68.2,158.17,81.2s105.447-16,158.169-16.5,105.446,27.5,158.17,32c28.031,2.393,56.062-1.858,84.093-6.108,24.692-3.744,49.384-7.488,74.077-6.691,52.722,1.6,105.445,24,158.169,5.8s105.445-76.8,158.169-95.2c52.724-18.3,105.445,3.7,158.169-27.8A152.372,152.372,0,0,0-4118-4925h0v144Z"
              transform="translate(5558 4925)"></path>
      </svg>
    </>
  )
}
