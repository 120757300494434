import { Link } from "gatsby";
import * as React from "react"
import * as styles from "./Anchors.module.scss";

export const Anchors = () => {

  return (
    <section className={styles.anchors}>
      <a className={styles.anchor} href={'#aires'}>
        <svg className={styles.svg} width="60" height="60" viewBox="0 0 60 60" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.5 43.125V52.5H16.875L44.525 24.85L35.15 15.475L7.5 43.125ZM51.775 17.6C52.75 16.625 52.75 15.05 51.775 14.075L45.925 8.22499C45.6937 7.9932 45.419 7.8093 45.1166 7.68383C44.8141 7.55836 44.4899 7.49377 44.1625 7.49377C43.8351 7.49377 43.5109 7.55836 43.2084 7.68383C42.906 7.8093 42.6313 7.9932 42.4 8.22499L37.825 12.8L47.2 22.175L51.775 17.6Z"
            fill="#00602C"/>
        </svg>
        Création d'aires de jeux
      </a>
      <a className={styles.anchor} href={'#maintenance'}>
        <svg className={styles.svg} width="60" height="60" viewBox="0 0 60 60" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M56.7496 47.5L33.9996 24.75C36.2496 19 34.9996 12.25 30.2496 7.50003C25.2496 2.50003 17.7496 1.50003 11.7496 4.25003L22.4996 15L14.9996 22.5L3.9996 11.75C0.999604 17.75 2.2496 25.25 7.2496 30.25C11.9996 35 18.7496 36.25 24.4996 34L47.2496 56.75C48.2496 57.75 49.7496 57.75 50.7496 56.75L56.4996 51C57.7496 50 57.7496 48.25 56.7496 47.5Z"
            fill="#00602C"/>
        </svg>
        La maintenance, ce n'est pas un jeu !
      </a>
      <Link className={styles.anchor} to={'/realisations'}>
        <svg className={styles.svg} width="60" height="60" viewBox="0 0 60 60" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M30 38C34.4183 38 38 34.4183 38 30C38 25.5817 34.4183 22 30 22C25.5817 22 22 25.5817 22 30C22 34.4183 25.5817 38 30 38Z"
            fill="#00602C"/>
          <path
            d="M22.5 5L17.925 10H10C7.25 10 5 12.25 5 15V45C5 47.75 7.25 50 10 50H50C52.75 50 55 47.75 55 45V15C55 12.25 52.75 10 50 10H42.075L37.5 5H22.5ZM30 42.5C23.1 42.5 17.5 36.9 17.5 30C17.5 23.1 23.1 17.5 30 17.5C36.9 17.5 42.5 23.1 42.5 30C42.5 36.9 36.9 42.5 30 42.5Z"
            fill="#00602C"/>
        </svg>
        Réalisations
      </Link>
    </section>
  )
}